<template>
  <div class="lg:flex items-start mb-6">
    <div class="lg:sticky ml-8 lg:top-44 lg:ml-0 mt-14 lg:mt-0">
      <m-calendar :date="date"></m-calendar>
    </div>

    <div class="flex flex-grow flex-col">
      <!-- Loop with the event info -->
      <div
        v-for="event in events"
        :key="`${event.e.id}-${event.cid}`"
        class="flex flex-grow text-grey3"
        :class="{ 'meetric-welcome-meeting': welcomeMeetingId == event.e.id }"
      >
        <event-item
          :event="event"
          :meetric-info="meetricContents[event.e.id]"
          :highlight="highlight"
          :guests="guests(event.e)"
          class="mb-4"
        >
        </event-item>
        <!-- Time of the event on DESKTOP -->
      </div>
    </div>
  </div>
</template>

<script>
import MCalendar from './MCalendar';
import EventItem from './EventItem';
import { getOverview } from '@/api/Cherry';
import { getMeetingAttendees } from '@/components/Utils';

export default {
  name: 'MeetingsListGroup',
  components: {
    MCalendar,
    EventItem,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    highlight: {
      type: String,
      required: false,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      meetricContents: {},
    };
  },
  watch: {
    events: function () {
      // if there are meetings added to the group, get the overview
      this.getMissingOverview();
    },
  },
  computed: {
    welcomeMeetingId() {
      return this.$store.getters['welcomeEventId'];
    },
  },
  mounted() {
    this.getMissingOverview();
  },
  methods: {
    getMissingOverview() {
      this.events.forEach((e) => {
        if (!(e.e.id in this.meetricContents)) {
          if (e.mc) {
            this.$set(this.meetricContents, e.e.id, e.mc);
          } else {
            getOverview(e.cid, e.e.id)
              .then((r) => {
                if (!r.error) {
                  this.$set(this.meetricContents, e.e.id, r);
                } else {
                  this.$set(this.meetricContents, e.e.id, {
                    public: {
                      has_notes: false,
                      actions: { total: 0, complete: 0, incomplete: 0 },
                    },
                    private: {
                      has_notes: false,
                      actions: { total: 0, complete: 0, incomplete: 0 },
                    },
                  });
                }
              })
              .catch((err) => {
                console.error('Unable to fetch meeting overview', err);
              });
          }
        }
      });
    },
    guests(event) {
      return getMeetingAttendees(event);
    },
  },
};
</script>
