<template>
  <div
    class="cal-container relative border-solid border border-grey6 bg-white dark:bg-base5 mb-5"
  >
    <span class="calendaricon text-white" :class="calStyle">{{
      dayString
    }}</span>
    <strong style="margin-top:0.1rem;">
      {{ dateNum }}
    </strong>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Calendar',
  components: {},
  props: {
    date: {
      type: String,
      required: true,
      default: '',
    },
    baseDate: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    baseDateStr() {
      // if not defined, use TODAY as a base
      if (this.baseDate != '') return this.baseDate;
      else return moment().format('YYYY-MM-DD');
    },
    dateNum() {
      return moment(this.date).format('D');
    },
    isPast() {
      return moment(this.date).isBefore(moment(this.baseDateStr));
    },
    isToday() {
      return this.date == this.baseDateStr;
    },
    dayString() {
      if (this.isToday) return 'TODAY';

      const dateTomorrowStr = moment(this.baseDateStr)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      if (this.date === dateTomorrowStr) return 'TOMO';

      const dateYesterdayStr = moment(this.baseDateStr)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      if (this.date === dateYesterdayStr) return 'YEST';

      const plusSixDays = moment(this.baseDateStr).add(7, 'days');
      const minusSixDays = moment(this.baseDateStr).subtract(7, 'days');
      const dateMoment = moment(this.date);
      if (dateMoment.isBetween(minusSixDays, plusSixDays))
        return dateMoment.format('ddd').toUpperCase();
      else return dateMoment.format('MMM').toUpperCase();
    },
    calStyle() {
      if (this.isToday) return 'bg-base2';
      if (this.isPast) return 'bg-grey4';
      return 'bg-grey2';
    },
  },
};
</script>

<style scoped>
.cal-container {
  border-radius: 0.65rem;
  width: 2.9rem;
  height: 3rem;
}

/* Day of the week colour in calendar icon */
.dark .calendaricon {
  color: #151515;
}

.cal-container span {
  @apply font-bold text-xs;
  width: 100%;
  height: 1.2rem;
  position: absolute;
  padding-top: 0.2rem;
  top: 0;
  border-radius: 0.65rem 0.65rem 0 0;
  text-align: center;
}
.cal-container strong {
  @apply font-semibold text-grey2 text-base;
  width: 100%;
  position: absolute;
  top: 1.2rem;
  text-align: center;
}
</style>
