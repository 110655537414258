<template>
  <div class="flex-grow flex-col lg:w-4/5 lg:m-auto">
    <div v-if="!ready" class="w-full">
      <div v-for="i in 3" :key="`i-${i}`" class="mt-7">
        <div class="flex flex-col lg:flex-row">
          <m-placeholder
            class="w-12 h-12 lg:w-8 lg:h-8 rounded-lg mx-7 lg:mx-0"
          ></m-placeholder>
          <div class="flex justify-between w-full">
            <m-placeholder
              class="mt-6 lg:mt-0 mx-7 lg:mx-0 w-full lg:w-36 lg:mx-6 h-12 lg:h-8 rounded-lg my-auto"
            ></m-placeholder>
            <m-placeholder
              class="hidden lg:flex flex-grow h-8 rounded-lg my-auto"
            ></m-placeholder>
          </div>
        </div>
        <div v-for="j in 4" :key="`j-${j}`">
          <div class="mt-3 lg:mt-7 flex justify-between w-full">
            <div class="hidden lg:flex w-8"></div>
            <m-placeholder
              class="mt-3 lg:mt-0 mx-7 lg:mx-0 w-full lg:w-36 lg:mx-6 h-12 lg:h-8 rounded-lg my-auto"
            ></m-placeholder>
            <m-placeholder
              class="hidden lg:flex flex-grow h-8 rounded-lg my-auto"
            ></m-placeholder>
          </div>
        </div>
      </div>
    </div>

    <template v-if="defer(1)">
      <div v-if="ready" class="w-full lg:mt-10">
        <div
          v-if="!meetingListEmpty && accountPlan <= 1"
          class="text-grey2 text-sm text-center opacity-75 mx-8 lg:mx-0 mb-14 lg:mb-6"
        >
          Showing meetings 15 days before and after today. Install the
          <a-link
            segmentName="Install extension"
            href="https://chrome.google.com/webstore/detail/meetric/imjdlmoaemnjongiaddmdgmpnomppdml"
            >Chrome extension</a-link
          >
          to see notes in your calendar. Alternatively, premium accounts have
          <a-link
            href="https://meetric.notion.site/Meetric-Pro-231289c089e948b79f235c006e83c582"
            segmentName="Premium intent: solo, meeting-list-bottom"
            >unlimited history</a-link
          >.
        </div>

        <!--S LOAD MORE -->
        <div
          v-if="accountPlan >= 2 && loadPast != loadEnum.NORESULT"
          class="flex justify-center mb-6"
        >
          <m-button
            button-style="btn-tertiary"
            :pending="loadPast == loadEnum.LOADING"
            icon="📋"
            class="mr-2"
            @click="loadMore(loadEnum.PAST)"
          >
            Load more
          </m-button>
        </div>
        <!--E LOAD MORE -->

        <template v-for="(events, day) in groupedEvents">
          <meetings-list-group
            :key="day"
            :date="day"
            :events="events"
            :highlight="highlightEventId"
          >
          </meetings-list-group>
        </template>

        <!--S LOAD MORE -->
        <div
          v-if="accountPlan >= 2 && loadFuture != loadEnum.NORESULT"
          class="flex justify-center -mt-4 mb-6"
        >
          <m-button
            button-style="btn-tertiary"
            :pending="loadFuture == loadEnum.LOADING"
            icon="📋"
            class="mr-2"
            @click="loadMore(loadEnum.FUTURE)"
          >
            Load more
          </m-button>
        </div>
        <!--E LOAD MORE -->

        <div
          v-if="
            meetingListEmpty &&
            !(lastFilteredResult.length > defaultMeetingList.length)
          "
          class="text-grey2 text-center p-3"
        >
          Oops, we couldn’t find many meetings in your calendar. Adjust your
          <router-link
            class="underline"
            :to="{
              name: 'profile',
            }"
            @click.native="trackGoToPrefs"
            >preferences</router-link
          >
          to show other calendars/events.
        </div>

        <div
          v-if="!meetingListEmpty && lastFilteredResult.length == 0"
          class="text-grey2 text-center p-3"
        >
          Oops, no meetings with notes match your search.
        </div>

        <div
          v-if="!meetingListEmpty && accountPlan <= 1"
          class="text-grey2 text-sm text-center opacity-75 mx-8 lg:mx-0 mb-14 lg:mb-6"
        >
          Showing meetings 15 days before and after today. Install the
          <a-link
            segmentName="Install extension"
            href="https://chrome.google.com/webstore/detail/meetric/imjdlmoaemnjongiaddmdgmpnomppdml"
            >Chrome extension</a-link
          >
          to see notes in your calendar. Alternatively, premium accounts have
          <a-link
            href="https://meetric.notion.site/Meetric-Pro-231289c089e948b79f235c006e83c582"
            segmentName="Premium intent: solo, meeting-list-bottom"
            >unlimited history</a-link
          >.
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import {
  getMeetingAttendees,
  sortObj,
  getDatetimeFromEvent,
} from '@/components/Utils';
import { getAllCalendarEvents } from '@/api/Google';
import MeetingsListGroup from '@/views/Meetings/MeetingsListGroup';
import MPlaceholder from '@/components/UI/MPlaceholder';
import Defer from '@/mixin/Defer';
import ALink from '@/components/UI/ALink';
import MButton from '@/components/UI/MButton';

export default {
  name: 'MeetingsList',
  components: {
    MeetingsListGroup,
    MPlaceholder,
    ALink,
    MButton,
  },
  mixins: [Defer()],
  props: {
    toNextMeeting: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      highlightEventId: '',
      lastFilteredResult: [], // what you see as result
      meetricContentsDefault: {}, // cache of meeting overview so we don't have to fetch it again in child
      allWithNotes: [], // longer period of all google meetings with notes
      fetchedProfiles: {}, // record of what profiles was fetched
      loadEnum: Object.freeze({
        READY: 0, // noop
        PAST: 1, // button to load more in past
        FUTURE: 2, // button to load more in future
        LOADING: 3, // button is pending
        NORESULT: 4, // no result from last fetch (hide button)
      }), // enum for load mode buttons
      loadPast: null,
      loadFuture: null,
      firstEvent: null,
    };
  },
  watch: {
    toNextMeeting: function () {
      this.onToday();
    },
  },
  computed: {
    accountPlan() {
      return this.$store.getters['plan'];
    },
    meetingListEmpty() {
      const justafew = this.defaultMeetingList.length < 3;
      if (justafew) {
        const props = {
          numberOfMeetings: this.defaultMeetingList.length,
          errorMsg: 'nomeetings',
          text:
            'Oops, we couldn’t find many meetings in your calendar. Adjust your preferences or to show other calendars/events.',
        };
        window.meetric.track('Error', props);
      }

      return justafew;
    },
    calendarIds() {
      return this.$store.getters['selectedCalendars'];
    },
    filterSolo() {
      return this.$store.getters['preferenceShowSolo'];
    },
    filterAllDay() {
      return this.$store.getters['preferenceShowAllDay'];
    },
    defaultMeetingList() {
      return this.$store.getters['getMeetingList'];
    },
    groupedEvents() {
      const _this = this;
      const groups = this.lastFilteredResult.reduce(function (obj, item) {
        const key = item.event.start.dateTime
          ? moment(item.event.start.dateTime).format('YYYY-MM-DD')
          : moment(item.event.start.date).format('YYYY-MM-DD');

        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        obj[key].push({
          e: item.event,
          cid: item.cid,
          mc: _this.meetricContentsDefault[item.event.id]
            ? _this.meetricContentsDefault[item.event.id]
            : null,
        });

        // Return the object to the next item in the loop
        return obj;
      }, {});
      const sortedGroups = sortObj(groups);
      Object.keys(sortedGroups).forEach((key) => {
        sortedGroups[key] = sortedGroups[key].sort(compareEvents);
      });

      return sortedGroups;
    },
  },
  mounted() {
    // events for meetingList
    this.fetchDefaultEvents();
    this.loadPast = this.loadEnum.READY;
    this.loadFuture = this.loadEnum.READY;
  },

  methods: {
    loadMore(loadType) {
      let gParams = {
        maxResults: 30, //load 70 at time
      };
      const eventKeys = Object.keys(this.groupedEvents);
      if (loadType == this.loadEnum.PAST) {
        this.loadPast = this.loadEnum.LOADING;
        // first event in the first group
        let firstEventStart = this.groupedEvents[eventKeys[0]]?.[0]?.e?.start;
        let dateTime = firstEventStart?.dateTime
          ? firstEventStart.dateTime
          : firstEventStart?.date;
        if (!dateTime) {
          console.error('We could not find start time of first event');
          return;
        }
        gParams.timeMax = moment(dateTime).format(); // first event start
        gParams.timeMin = moment(dateTime).subtract(3, 'months').format();
        gParams.maxResults = 2500; // we need to   get all events as they are ASC
      } else {
        this.loadFuture = this.loadEnum.LOADING;

        // first and last event in last group
        let lastGroupFirstEvent = this.groupedEvents[eventKeys?.at(-1)]?.[0]?.e;
        let lastGroupLastEvent = this.groupedEvents[eventKeys?.at(-1)]?.at(-1)
          ?.e;
        if (!lastGroupFirstEvent || !lastGroupLastEvent) {
          console.error('We could not find end time of last event');
          return;
        }
        let dateTime = '';

        if (!lastGroupLastEvent.end?.dateTime) {
          // last event is all day
          dateTime = lastGroupLastEvent.end?.date;
        } else if (
          lastGroupLastEvent.end?.dateTime &&
          lastGroupFirstEvent.end?.dateTime
        ) {
          // last event is not all day and first event is not all day
          dateTime = lastGroupLastEvent.end?.dateTime;
        } else if (
          lastGroupLastEvent.end?.dateTime &&
          !lastGroupFirstEvent.end?.dateTime
        ) {
          // last event is not all day but first is - use first
          dateTime = lastGroupFirstEvent.end?.date;
        }
        if (!dateTime) {
          console.error('We could not determine end time of last event');
          return;
        }
        gParams.timeMax = moment(dateTime).add(2, 'years').format(); // big in future
        gParams.timeMin = moment(dateTime).format(); // last evet end
      }

      getAllCalendarEvents(this.calendarIds, gParams).then((events) => {
        // console.log(loadType, events);
        if (loadType == this.loadEnum.PAST) {
          if (events.length == 0) {
            this.loadPast = this.loadEnum.NORESULT;
            return;
          }
          this.loadPast = this.loadEnum.READY;
          const filtered = this.filterLocally(events.slice(-30)); // only last 70
          this.lastFilteredResult = [...filtered, ...this.lastFilteredResult];
        } else {
          if (events.length == 0) {
            this.loadFuture = this.loadEnum.NORESULT;
            return;
          }
          this.loadFuture = this.loadEnum.READY;
          const filtered = this.filterLocally(events);
          this.lastFilteredResult = [...this.lastFilteredResult, ...filtered];
        }
      });
    },
    trackGoToPrefs() {
      window.meetric.track('Go to preferences');
    },

    filterLocally(events, options = { ignoreSettings: false }) {
      if (!events) return;

      const result = events.filter((item) => {
        const attendees = getMeetingAttendees(item.event);

        //  filter by appointments (no guests)
        if (!options.ignoreSettings && !this.filterSolo) {
          // solo include just organiser
          if (attendees.length <= 1) {
            return false;
          }
        }

        // filter by all-day
        if (
          !options.ignoreSettings &&
          !this.filterAllDay &&
          item.event.start.date
        ) {
          return false;
        }

        // fetch profiles to display name if available
        this.fetchProfiles(attendees);

        return true;
      });

      return result;
    },
    fetchProfiles(attendees) {
      if (!Array.isArray(attendees)) return;

      for (let i = 0; i < attendees.length; i++) {
        if (attendees[i].email in this.fetchedProfiles) continue;
        if (this.$store.getters.profile(attendees[i].email)) continue;

        this.$store.dispatch('fetchProfile', attendees[i].email);
        this.fetchedProfiles[attendees[i].email] = true;
      }
    },

    onToday() {
      // scroll to today group of events

      var todayPos = -1;
      let el =
        document.querySelector('.currentEvent') ||
        document.querySelector('.futureEvent');
      this.highlightEventId = el ? el.getAttribute('data-eventid') : '';

      if (el) {
        const rect = el.getBoundingClientRect();
        todayPos = rect.top + window.scrollY;
      }
      if (todayPos > 0) {
        window.scroll({ left: 0, top: todayPos - 190 }); // magic number in px
        // magic number was manually tested to get correct position of next meeting on the screen
      }
    },
    async fetchDefaultEvents() {
      // ready=true if there is already some result but reload it anyway
      // this is so there is no delay and empty screen
      if (this.defaultMeetingList.length > 0) {
        this.lastFilteredResult = this.filterLocally(this.defaultMeetingList);
        this.ready = true;
        await sleep(500); // wait little bit in case we have cached meetings before refresh
      }
      this.onToday();

      this.$store.dispatch('refreshMeetingList', this.calendarIds).then(() => {
        this.lastFilteredResult = this.filterLocally(this.defaultMeetingList);
        this.ready = true;
        this.disableTodayScroll = true;
        this.$nextTick(function () {
          this.onToday();
        });
      });
    },
  },
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function compareEvents(a, b) {
  if (getDatetimeFromEvent(a.e) < getDatetimeFromEvent(b.e)) {
    return -1;
  }
  if (getDatetimeFromEvent(a.e) > getDatetimeFromEvent(b.e)) {
    return 1;
  }
  return 0;
}
</script>
